// You can add global styles to this file, and also import other style files
//------------------------------------------------------------------------------
* { margin:0; padding:0; }
//------------------------------------------------------------------------------
// colour variables:
//------------------------------------------------------------------------------
:root {
--font-size: 0.85em;
--red: rgba(255, 0, 0, 0.5);
--orange: rgba(255, 160, 0, 0.5);
--yellow: rgba(255, 255, 0, 0.5);
--green: rgba(0, 180, 0, 0.5);
--blue: rgba(0, 0, 180, 0.5);
--transparent: transparent;

--default-bg-devel: rgba( 60, 255,  60, 0.7);
--default-bg-prod : rgba(255,  50,  50, 0.5);
//------------------------------------------------------------------------------
// light-mode
//------------------------------------------------------------------------------
--default-fg: #202020;
--default-bg: #f0f0f0;
--default-ol: #66ccff;

--message_done-bg: var(--green);
--message_error-bg: var(--red);

--input-fg: #202020;
--input-bg: #f0f0f0;
--input-bg-invalid: rgba(255,0,0,0.3);
--input-fg-disabled: #606060;
--input-bg-disabled: #e0e0e0;

// --select-fg-disabled: #606060;
// --select-bg-disabled: #e0e0e0;

--button-bd: #808080;
--button-bg-hover: #c0c0c0;
--button-fg-off: #d0d0d0;

--action-bg: #e0e0e0;
--action-bd: transparent;
--action-bg-hover: #c0c0c0;
--action_default-bg: rgba(200,200,200,0.5);
--action-fg-off: #d0d0d0;
--action-bg-off: #f4f4f4;
--action_delete-fg: #ff0000;
--action_delete-fg-hover: #ffffff;
--action_delete-bg-hover: #ff0000;

--tab-bg: #a0a0a0;
--tab-fg-hover: #ffffff;
--tab-fg-selected: var(--default-fg);
--tab-bg-selected: var(--default-bg);

--tr_odd-bg: #e0e0e0;
--tr_odd-bg-selected: #b0b0b0;
--tr_even-bg: #d0d0d0;
--tr_even-bg-selected: #a0a0a0;

--td_clickable-bg: #e0f0e0;
--td_clickable-bg-hover: #00d000;

--dropTarget-bg: #eeeeee;
--dropTarget-bd: #D1D1D1;
--dropTarget-bg-drag: #eeffee;
--dropTarget-sh-drag: #aaffaa;
--dropTarget-bg-dropped: #ffeeee;
}
//------------------------------------------------------------------------------
// dark-mode
//------------------------------------------------------------------------------
@media (prefers-color-scheme: dark) { :root {
--default-fg: #f0f0f0;
--default-bg: #202020;
// --default-ol: #66ccff;

// --message_done-bg: var(--green);
// --message_error-bg: var(--red);

--input-fg: #ffffff;
--input-bg: #808080;
// --input-bg-invalid: rgba(255,0,0,0.3);
--input-fg-disabled: #e0e0e0;
--input-bg-disabled: #404040;

// --select-fg-disabled: #d0d0d0;
// --select-bg-disabled: #303030;

// --button-bd: #808080;
// --button-bg-hover: #c0c0c0;
--button-fg-off: #f4f4f4;

--action-bg: #555555;
// --action-bd: transparent;
// --action-bg-hover: #c0c0c0;
// --action_default-bg: rgba(200,200,200,0.5);
--action-fg-off: #f4f4f4;
--action-bg-off: rgba(120,120,120,0.5);
// --action_delete-fg: #ff0000;
// --action_delete-fg-hover: #ffffff;
// --action_delete-bg-hover: #ff0000;

--tab-bg: #b0b0b0;
--tab-fg-hover: #202020;
// --tab-fg-selected: var(--default-fg);
// --tab-bg-selected: var(--default-bg);

--tr_odd-bg: #303030;
--tr_odd-bg-selected: #707070;
--tr_even-bg: #404040;
--tr_even-bg-selected: #808080;

--td_clickable-bg: #505050;
--td_clickable-bg-hover: #606060;

--dropTarget-bg: #808080;
// --dropTarget-bd: #eeeeee;
--dropTarget-bg-drag: #808080;
// --dropTarget-sh-drag: #aaffaa;
--dropTarget-bg-dropped: #808080;
}}
//------------------------------------------------------------------------------
html {
    font-size: var(--font-size);
}
body {
    font-family: "Helvetica Neue", Arial, sans-serif;
    color: var(--default-fg);
    background-color: var(--default-bg);
    min-width: 100em;
    overflow-y: hidden;
}
@media screen and (max-height:1024px) {
    body { overflow: auto; }
}

code,
input[type=email   ],
input[type=file    ],
input[type=number  ],
input[type=password],
input[type=search  ],
input[type=text    ],
input[type=tel     ],
input[type=url     ],
textarea,
select {
    width: 100%;
    height: 2.3em;
    color: var(--input-fg);
    background-color: var(--input-bg);
    border-width: 2px;
    &[disabled] {
        color: var(--input-fg-disabled);
        background-color: var(--input-bg-disabled);
        border-color: var(--input-bg-disabled);
    }
}
input[type=number] {
    text-align:right;
}
select[disabled] {
    -moz-appearance: none;
    -webkit-appearance: none;
}
textarea { height: unset; }

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
    background-color: var(--input-bg-invalid);
}

button, input[type='button'], input[type='reset'], input[type='submit'],
.button, .btn, td button {
    font-family: sans-serif;
    color: var(--default-fg);
    border: 1px solid var(--button-bd);
    padding: 0.2em 0.6em;
    font-size: 0.9em;
    font-weight: 400;
    height: 2.3em;
    line-height: 1.5em;
    min-width: 5em;
    text-transform: none;
    &.back {
        font-size: 0.5em;
        font-weight: bold;
        line-height: 2.1em;
        margin-right: 0.75em;
    }
    &:hover {
        background-color: var(--button-bg-hover);
        border: 1px solid transparent;
    }
    &.off     , &.off:hover,
    &:disabled, &:disabled:hover {
        color: var(--button-fg-off);
        background-color: var(--default-bg);
    }
}
//------------------------------------------------------------------------------
MenuTag {
    button, input {
        &.btn, &.button {
            margin      : 2px 1px;
        }
        &.logo {
            margin-left : -5px;
            border      : 0;
            img {
                width: 50px;
                &:hover {
                    background-color: var(--button-bg);
                }
            }
        }
    }
    div {
        &.left  {
            width       : 30%;
            float       : left;
        }
        &.right {
            width       : 70%;
            float       : right;
        }
        &.debug {
            position    : absolute;
            top         : 5px;
            left        : 90px;
            min-width   : 10em;
            z-index     : 10;
        }
    }
}
.development-window { background-color: var(--default-bg-devel); }
.production-window  { background-color: var(--default-bg-prod ); }

MainTag {
    & h1, & h2, & h3, & h4 {
        margin: 5px 0;
    }
    h6 {
        margin: 1em 0 0.5em 0;
        display: flex;
        gap: 1em;
        align-items: center;
        font-size: 1.2em;

        &::before, &::after {
          height: 1px;
          background: currentColor;
        }

        &::before { flex: 0; }
        &::after  { flex: 1; }

        &[align='centre'], &[align='center'] {
            &::before { flex: 1; }
            &::after  { flex: 1; }
        }
        &[align='left' ]{
            &::before { flex: 0; }
            &::after  { flex: 1; }
        }
        &[align='right']{
            &::before { flex: 1; }
            &::after  { flex: 0; }
        }
    }
}

dialog {
    background-color: transparent;
}
dialog::backdrop {
    background-color: rgba(0,0,0,0.4);
}
dialog.BusyTag::backdrop {
    background-color: rgba(0,0,0,0.1);
}

ModalTag {
    div.modal-position {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    div.modal-content {
        display: block;
        padding: 2em 2.5em;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
        color: var(--default-fg);
        background-color: var(--default-bg);
        font-size: 1em;
        margin: 0 auto;
        border: 0.1em solid var(--default-fg);
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.75em;
        border-bottom-right-radius: 0.75em;
        &.tiny      { width: 30%; }
        &.small     { width: 50%; }
        &.medium    { width: 70%; }
        &.large     { width: 90%; }
    }
    div.rows.container {
        padding-bottom: 2em;
    }
}

ItemControls > *,
ListControls > * {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding: 0 1em;
}

.div-menu.container {
//     width: calc(100% - 1em);
    max-width: 100%;
//     margin-left: auto;
//     margin-right: auto;
    margin-left: 0;
    margin-right: 0;
    overflow: auto;
}
.rows.container {
    width: calc(100% - 1em);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    margin-bottom: 7px;
    overflow: auto;
}
hr {
    height: 2px;
    border-color: var(--default-fg);
    &.menu-hr {
        margin: 5px 0 0 0;
    }
    &.item-hr {
        border-width: 2px;
        margin: 1em -15px;
    }
}
.list.container {
    width: 100%;
    max-width: 100%;
    margin: 7px 0;
    padding: 0;
    overflow: auto;
}
.tabs.container,
.tabstabs,
.tabsbody {
    width: calc(100% - 1em);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 7px 0.5em;
}
.tabs.container {
    height: calc(100% - 15px);
    padding: 0;
    border-color: var(--transparent);
}
.tabstabs {
    width: calc(100% - 2em);
    margin-left: 1em;
}
.tabsbody {
    border: 2px solid var(--tab-bg);
    border-radius: 0.75em;
    margin-top: -0.62em;
}
tr.critLabels > th {
    border: 0;
}
tr.critLabels > th > label {
    white-space: pre-line;
}
thead > tr > th > span {
    position: relative;
    top: 1px
}
tfoot > tr > th {
    border-bottom: 0;
}
th {
    padding: 0;
    & input.crit {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 2em;
        padding: 0 0.3em;
        min-width: 4em;
        font-weight: bold;
        width: calc(100% + 2px);
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
    label {
        padding: 0 0.3em;
        position: relative;
        top: 0.3em;
    }
}
td {
    padding: 0;
    & > div, & > span { width: 100%; }
    &.lines {
        white-space: pre-line;
        margin: 0;
    }
    &.clickable {
        background-color: var(--td_clickable-bg);
        border: 0;
        &:hover {
            cursor: pointer;
            background-color: var(--td_clickable-bg-hover);
        }
    }
}
th > input[type=checkbox],
td > input[type=checkbox] {
    width: 1.4em;
    margin-left: 0.35em;
}

DateLC[name='date1'] input { border-radius: 0; }

MainTag      h3 button.btn.back { margin-left: 8px; }
MainTag form h3 button.btn.back { margin-left: 0px; }

logo {
    div { width: 60%; margin: auto; margin-top: 3em; }
    img { width: 100%; }
}

.row .btn {
    margin-top: 7px;
}

.actions {
    margin-left: -0.5em;
    margin-right: -0.5em;
    &.top {
        margin-top: 0em;
        &.header {
            margin-top: 0.5em;
        }
    }
    &.bot.floatr {
        max-width: 80%;
        text-align: right;
    }
    .btn {
        color: var(--default-fg);
        background-color: var(--action-bg);
        border-color: var(--action-bd);
        margin: 0 0.5em;
        &.submit {
            background-color: var(--action_default-bg);
        }
        &:hover {
            background-color: var(--action-bg-hover);
        }
        &.delete, &.forceEdit {
            margin-left: 3em;
            margin-right: 3em;
            color: var(--action_delete-fg);
            font-weight: 500;
            &:hover {
                color: var(--action_delete-fg-hover);
                background-color: var(--action_delete-bg-hover);
            }
        }
        &.off, &.off:hover {
            cursor: default;
            color: var(--action-fg-off);
            background-color: var(--action-bg-off);
            border-color: var(--action-bg-off);
        }
    }
}
.tabshead .actions.top > .btn {
    margin-top: 0.5em;
}

// table.list {
table {
    width: calc(100% - 1px);
    border-collapse: collapse;
//     border-spacing: 1px;
    margin: 0;
    & > tbody > tr {
        border: 0;
        &:nth-child(1),
        &:nth-child(2) {
            -webkit-transform: translate3d(0,0,0);
        }
        &:nth-child(odd) {
            background-color: var(--tr_odd-bg);
        }
        &:nth-child(even) {
            background-color: var(--tr_even-bg);
        }
        &.HIDDEN {
            display: none;
        }
        &.SELECT:nth-child(odd) {
            background-color: var(--tr_odd-bg-selected);
        }
        &.SELECT:nth-child(even) {
            background-color: var(--tr_even-bg-selected);
        }
        & {
            padding: 0 0.2em;
            vertical-align: top;
        }
        &.group     > td.isKey,
        &.groupLast > td.isKey {
            color: var(--transparent);
        }
        &.single, &.groupLast {
            border-bottom: 1px dashed #606060;
        }
        & > td {
            border: inherit;
            padding: 0 0.2em;
            vertical-align: top;
        }
    }
}
td {
    border: 0;
    &.left-border {
        border-left: 1px solid #606060;
    }
    & button {
        font-size: small;
        width:auto;
        height: 20px;
        padding: 0 0.5em;
        color: var(--default-fg);
        border-radius: 0.3em;
    }
    & img {
        width : auto; max-width : 100%;
        height: auto; max-height: 100px;
    }
}

.message {
    margin-top: 0.5em;
    &.done {
        padding: 3px 7px;
        background-color: var(--message_done-bg);
        border-radius: 0.5em;
    }
    &.error {
        padding: 3px 7px;
        background-color: var(--message_error-bg);
        border-radius: 0.5em;
    }
}

div.stab {
    height: auto;
    background-color: var(--tab-bg);
    border: 2px solid var(--tab-bg);
    border-radius: 0.75em 0.75em 0 0;
    display: inline-block;
    padding: 0.2em 1em;
    position: relative;
    top: 1px;
    &:hover {
        color: var(--tab-fg-hover);
        background-color: var(--tab-bg);
        border-color: var(--tab-bg);
    }
    &.selected,
    &.selected:hover {
        color: var(--tab-fg-selected);
        background-color: var(--tab-bg-selected);
        border-bottom-color: var(--tab-bg-selected);
        top: 1.5px;
    }
}

// div.container code,
div.container input[type=email   ],
div.container input[type=file    ],
div.container input[type=number  ],
div.container input[type=password],
div.container input[type=search  ],
div.container input[type=text    ],
div.container input[type=tel     ],
div.container input[type=url     ],
// div.container textarea,
div.container select {
    height: 2.3em;
    width: 100%;
    padding: 0 3px;
}

div.container input[type="checkbox"] {
    width: initial;
}

div.hidden-submit, div.hidden-submit input {
    border: 0 none;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

// invisible input field for when all else is dark
div.pagefoot input.key_catcher {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    border-width: 0;
    outline: none;
    cursor: none;
}

div.dropTarget {
    position: relative;
    width: 100%;
    background-color: var(--dropTarget-bg);
    border: 2px solid var(--dropTarget-bd);
    border-radius: 0.3em;
    &.file {
        height: 2.3em;
    }
    &.image {
        height: 30em;
    }
    &.dragover {
        background-color: var(--dropTarget-bg-drag);
        box-shadow: .5em .5em var(--dropTarget-sh-drag);
    }
    &.dropped {
        background-color: var(--dropTarget-bg-dropped);
    }
    & img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
    }
    & button {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        padding: 0;
        margin: auto;
    }
}
//------------------------------------------------------------------------------
.w0 {width: 0%;}.w1 {width: 1%;}.w2 {width: 2%;}.w3 {width: 3%;}.w4 {width: 4%;}.w5 {width: 5%;}.w6 {width: 6%;}.w7 {width: 7%;}.w8 {width: 8%;}.w9 {width: 9%;}
.w10{width:10%;}.w11{width:11%;}.w12{width:12%;}.w13{width:13%;}.w14{width:14%;}.w15{width:15%;}.w16{width:16%;}.w17{width:17%;}.w18{width:18%;}.w19{width:19%;}
.w20{width:20%;}.w21{width:21%;}.w22{width:22%;}.w23{width:23%;}.w24{width:24%;}.w25{width:25%;}.w26{width:26%;}.w27{width:27%;}.w28{width:28%;}.w29{width:29%;}
.w30{width:30%;}.w31{width:31%;}.w32{width:32%;}.w33{width:33%;}.w34{width:34%;}.w35{width:35%;}.w36{width:36%;}.w37{width:37%;}.w38{width:38%;}.w39{width:39%;}
.w40{width:40%;}.w41{width:41%;}.w42{width:42%;}.w43{width:43%;}.w44{width:44%;}.w45{width:45%;}.w46{width:46%;}.w47{width:47%;}.w48{width:48%;}.w49{width:49%;}
.w50{width:50%;}.w51{width:51%;}.w52{width:52%;}.w53{width:53%;}.w54{width:54%;}.w55{width:55%;}.w56{width:56%;}.w57{width:57%;}.w58{width:58%;}.w59{width:59%;}
.w60{width:60%;}.w61{width:61%;}.w62{width:62%;}.w63{width:63%;}.w64{width:64%;}.w65{width:65%;}.w66{width:66%;}.w67{width:67%;}.w68{width:68%;}.w69{width:69%;}
.w70{width:70%;}.w71{width:71%;}.w72{width:72%;}.w73{width:73%;}.w74{width:74%;}.w75{width:75%;}.w76{width:76%;}.w77{width:77%;}.w78{width:78%;}.w79{width:79%;}
.w80{width:80%;}.w81{width:81%;}.w82{width:82%;}.w83{width:83%;}.w84{width:84%;}.w85{width:85%;}.w86{width:86%;}.w87{width:87%;}.w88{width:88%;}.w89{width:89%;}
.w90{width:90%;}.w91{width:91%;}.w92{width:92%;}.w93{width:93%;}.w94{width:94%;}.w95{width:95%;}.w96{width:96%;}.w97{width:97%;}.w98{width:98%;}.w99{width:99%;}
.w100{width:100%;}
//------------------------------------------------------------------------------
.hide       { display: none; }
.center,
.centre     { text-align: center; }
.right      { text-align: right; }
.left       { text-align: left; }

.lines      { white-space-collapse: preserve-breaks; }

.floatl     { float:left ;  }
.floatr     { float:right;  }

.red        { color: var(--red   ); }
.orange     { color: var(--orange); }
.yellow     { color: var(--yellow); }
.green      { color: var(--green ); }
.blue       { color: var(--blue  ); }
//------------------------------------------------------------------------------
button, code, input[type], select, textarea, .btn {
    &:focus {
        border-color: transparent;
        outline: 3px solid var(--default-ol);
    }
}
//------------------------------------------------------------------------------
@media print {
    @page { size: landscape; }
    * { font-size: 0.45em; color: black !important; }
    MenuTag, .actions { display:none; }
    .pagebody { height: 100% !important; }
    table, tr { page-break-inside:avoid; }
}
//------------------------------------------------------------------------------
